<template>
    <div class="modal" :class="showModalFlag ? 'is-active' : '' ">
      <div class="modal-background"></div>
      <div v-if="showModalFlag" class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ student.name() }}</p>
          <button class="delete" aria-label="close" v-on:click="cancelModal()">></button>
        </header>
        <section class="modal-card-body">
          <article class="media tile is-child box">
              <div class="media" style="min-height: 80px">
                <div class="media-content">
                  <div class="select is-rounded">
                    <select v-model="currentrm">
                      <option v-for="x in regMarks" :key="x.ident" :value="x.ident">{{ x.description }}</option>
                    </select>
                  </div>
                </div>
              </div>
          </article>
            
            
          
        </section>
        <footer class="modal-card-foot is-pulled-right" style="justify-content: flex-end;">
          <button class="button is-success is-pulled-right" v-on:click="okModal()">Ok</button>
          <button class="button is-pulled-right" v-on:click="cancelModal()">Cancel</button>
        </footer>
      </div>
    </div>
</template>


<script>


export default {
  name: "modal-student-register-single",
  

  data: function () {
    return {
        showModalFlag: false,
        okPressed: false,
        student: null,
        currentrm: null, 
        regPeriod: null, 
        regMarks: null,
      };
    },
    
  methods: {
    showModal: function(student, currentrm, regPeriod, regMarks) {
      this.okPressed = false;
      this.showModalFlag = true; 
      this.student = student
      this.currentrm = currentrm
      this.regPeriod = regPeriod
      this.regMarks = regMarks

      let instance = this

      //console.log([student, currentrm, regPeriod, regMarks])

      setTimeout(()=> {
        if (instance.showModalFlag) {
            instance.okModal()
        }
      },
      5000)
      
    },
    okModal: function() {
      this.okPressed = true;
      this.showModalFlag = false;
    },
    cancelModal: function() {
      this.okPressed = false;
      this.showModalFlag = false;
    }
  }
}
</script>