var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container has-background-white-ter"},[_c('section',{staticClass:"section",attrs:{"id":"users-timetable"}},[_c('div',{staticClass:"container"},[(_vm.group.length)?_c('div',{staticClass:"box",staticStyle:{"align-content":"flex-start","flex-direction":"row","justify-content":"space-between","display":"flex"}},[_c('h4',{staticClass:"title is-4 is-spaced"},[_vm._v("Tutor")]),_c('div',{staticClass:"field has-addons is-pulled-right"},[_vm._l((_vm.myTutors),function(tut,i){return _c('button',{key:tut.key,class:_vm.myTutors[i].visible
                ? ' button is-success is-selected'
                : 'button',attrs:{"disabled":_vm.myTutors.length === 1},on:{"click":function($event){return _vm.toggleClassVisibility(i)}}},[_vm._v(" "+_vm._s(tut.name)+" ")])}),(_vm.canRegister)?_c('button',{staticClass:"button is-primary is-pulled-right",on:{"click":function($event){return _vm.registerGroup()}}},[_vm._v(" Register ")]):_vm._e()],2)]):_vm._e(),_vm._l((_vm.students),function(grp,index){return _c('div',{key:index,staticClass:"tile is-ancestor"},_vm._l((grp),function(student,studentIndex){return _c('div',{key:student.AcademicRecordID,staticClass:"tile is-parent is-4"},[_c('article',{staticClass:"media tile is-child box"},[_c('div',{staticClass:"media",staticStyle:{"min-height":"80px"}},[_c('div',{staticClass:"media-left is-size-1"},[_c('figure',{staticClass:"image is-48x48"},[_c('a',{on:{"click":function($event){return _vm.showStudentModal(studentIndex + index * 3)}}},[(student.photo)?_c('img',{attrs:{"src":`data:image/png;base64,${student.photo}`}}):_c('img',{attrs:{"src":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBg+A8AAQQBAHAgZQsAAAAASUVORK5CYII="}})])])]),_c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4"},[_vm._v(_vm._s(student.allData.FirstName))]),_c('p',{staticClass:"subtitle is-6"},[_vm._v(_vm._s(student.allData.LastName))])]),_c('div',{staticClass:"media-right"},[(student.allData?.SEND)?_c('p',[(student.allData?.SEND['Teaching Advice'])?_c('a',{on:{"click":function($event){return _vm.showAlert(studentIndex + index * 3, 'Teaching Advice')}}},[_c('Icon',{attrs:{"icon":"pixelarticons:teach","width":"1.5em","height":"1.5em"}})],1):_vm._e(),(student.allData?.SEND['Access Arrangements'])?_c('a',{on:{"click":function($event){return _vm.showAlert(
                        studentIndex + index * 3,
                        'Access Arrangements'
                      )}}},[_c('Icon',{attrs:{"icon":"bi:ladder","width":"1.5em","height":"1.5em"}})],1):_vm._e(),(student.allData?.SEND['SEN Alert'] === 'TRUE')?_c('a',{on:{"click":function($event){return _vm.showAlert(
                        studentIndex + index * 3,
                        'SEN Alert',
                        'SEN Information'
                      )}}},[_c('Icon',{attrs:{"icon":"entypo:blackboard","width":"1.5em","height":"1.5em"}})],1):(student.allData?.SEND['SEN Information'])?_c('a',{on:{"click":function($event){return _vm.showAlert(studentIndex + index * 3, 'SEN Information')}}},[_c('Icon',{attrs:{"icon":"entypo:blackboard","width":"1.5em","height":"1.5em"}})],1):_vm._e(),(student.allData?.SEND['Medical Alert i'] === 'TRUE')?_c('a',{on:{"click":function($event){return _vm.showAlert(
                        studentIndex + index * 3,
                        'Medical Alert i',
                        'Medical Conditions Alert'
                      )}}},[_c('Icon',{attrs:{"icon":"healthicons:ambulance","width":"1.5em","height":"1.5em"}})],1):_vm._e()]):_vm._e()])]),_c('table',{staticClass:"table is-bordered is-fullwidth has-text-centered"},[_vm._m(0,true),_c('tbody',[_c('tr',[_c('td',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(student.attendance.am_f),expression:"student.attendance.am_f"}]},[_vm._v(_vm._s(student.attendance.am))])]),_c('td',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(student.attendance.pm_f),expression:"student.attendance.pm_f"}]},[_vm._v(_vm._s(student.attendance.pm))])])])])])])])}),0)}),(_vm.group.length === 0)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_c('article',{staticClass:"media"},[_c('div',{staticClass:"media-left is-size-1"},[_c('Icon',{staticClass:"is-3",attrs:{"icon":"mdi:timetable"}})],1),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[(_vm.loaded)?_c('p',[_vm._v("No group data found")]):_c('p',[_vm._v("Loading your group information...")])])])])])])]):_vm._e()],2)]),_c('modal',{ref:"myModal"}),_c('StudentModal',{ref:"myStudentModal"}),_c('RegisterModal',{ref:"myRegisterModal"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"has-background-primary"},[_c('th',[_vm._v("AM")]),_c('th',[_vm._v("PM")])])])
}]

export { render, staticRenderFns }