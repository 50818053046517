<template>
  <div class="container has-background-white-ter">
    <section id="users-timetable" class="section">
      <div class="container">
        <div
          class="box"
          style="
            align-content: flex-start;
            flex-direction: row;
            justify-content: space-between;
            display: flex;
          "
          v-if="group.length"
        >
          <h4 class="title is-4 is-spaced">Tutor</h4>

          <div class="field has-addons is-pulled-right">
            <button
              v-for="(tut, i) in myTutors"
              :disabled="myTutors.length === 1"
              :key="tut.key"
              @click="toggleClassVisibility(i)"
              :class="
                myTutors[i].visible
                  ? ' button is-success is-selected'
                  : 'button'
              "
            >
              {{ tut.name }}
            </button>

            <button
              class="button is-primary is-pulled-right"
              v-if="canRegister"
              v-on:click="registerGroup()"
            >
              Register
            </button>
          </div>
        </div>

        <div
          class="tile is-ancestor"
          v-for="(grp, index) in students"
          :key="index"
        >
          <div
            v-for="(student, studentIndex) in grp"
            :key="student.AcademicRecordID"
            class="tile is-parent is-4"
          >
            <article class="media tile is-child box">
              <div class="media" style="min-height: 80px">
                <div class="media-left is-size-1">
                  <figure class="image is-48x48">
                    <a v-on:click="showStudentModal(studentIndex + index * 3)">
                      <img
                        v-if="student.photo"
                        :src="`data:image/png;base64,${student.photo}`"
                      />
                      <img
                        v-else
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBg+A8AAQQBAHAgZQsAAAAASUVORK5CYII="
                      />
                    </a>
                  </figure>
                </div>
                <div class="media-content">
                  <p class="title is-4">{{ student.allData.FirstName }}</p>
                  <p class="subtitle is-6">{{ student.allData.LastName }}</p>
                </div>
                <div class="media-right">
                  <p v-if="student.allData?.SEND">
                    <a
                      v-on:click="
                        showAlert(studentIndex + index * 3, 'Teaching Advice')
                      "
                      v-if="student.allData?.SEND['Teaching Advice']"
                    >
                      <Icon
                        icon="pixelarticons:teach"
                        width="1.5em"
                        height="1.5em"
                      />
                    </a>

                    <a
                      v-on:click="
                        showAlert(
                          studentIndex + index * 3,
                          'Access Arrangements'
                        )
                      "
                      v-if="student.allData?.SEND['Access Arrangements']"
                    >
                      <Icon icon="bi:ladder" width="1.5em" height="1.5em" />
                    </a>

                    <a
                      v-on:click="
                        showAlert(
                          studentIndex + index * 3,
                          'SEN Alert',
                          'SEN Information'
                        )
                      "
                      v-if="student.allData?.SEND['SEN Alert'] === 'TRUE'"
                    >
                      <Icon
                        icon="entypo:blackboard"
                        width="1.5em"
                        height="1.5em"
                      />
                    </a>

                    <a
                      v-on:click="
                        showAlert(studentIndex + index * 3, 'SEN Information')
                      "
                      v-else-if="student.allData?.SEND['SEN Information']"
                    >
                      <Icon
                        icon="entypo:blackboard"
                        width="1.5em"
                        height="1.5em"
                      />
                    </a>

                    <a
                      v-on:click="
                        showAlert(
                          studentIndex + index * 3,
                          'Medical Alert i',
                          'Medical Conditions Alert'
                        )
                      "
                      v-if="student.allData?.SEND['Medical Alert i'] === 'TRUE'"
                    >
                      <Icon
                        icon="healthicons:ambulance"
                        width="1.5em"
                        height="1.5em"
                      />
                    </a>
                  </p>
                </div>
              </div>
              <table class="table is-bordered is-fullwidth has-text-centered">
                <thead>
                  <tr class="has-background-primary">
                    <th>AM</th>
                    <th>PM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span v-tooltip="student.attendance.am_f">{{
                        student.attendance.am
                      }}</span>
                    </td>
                    <td>
                      <span v-tooltip="student.attendance.pm_f">{{
                        student.attendance.pm
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </article>
          </div>
        </div>
        <div v-if="group.length === 0" class="columns">
          <div class="column">
            <div class="box">
              <article class="media">
                <div class="media-left is-size-1">
                  <Icon icon="mdi:timetable" class="is-3" />
                </div>
                <div class="media-content">
                  <div class="content">
                    <p v-if="loaded">No group data found</p>
                    <p v-else>Loading your group information...</p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal ref="myModal" />
    <StudentModal ref="myStudentModal" />
    <RegisterModal ref="myRegisterModal" />
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Modal from "../../components/Modal";
import { Icon } from "@iconify/vue2";
import { format } from "date-fns";
import { Student } from "../../classes/student";

import StudentModal from "../../components/school/pupilmodal";
import RegisterModal from "../../components/school/registersinglemodal";

import { structuredClone } from "core-js/actual";

export default {
  name: "SchoolTutorGroup",

  components: {
    Icon,
    Modal,
    StudentModal,
    RegisterModal,
  },

  data: function () {
    return {
      user: firebase.auth().currentUser,
      group: [],
      loaded: false,
      groupid: null,
      periodID: null,
      regDetails: {},
      regMarks: {},
      myTutors: [],
      students: [],
      canRegister: false,
    };
  },
  computed: {
    session() {
      return "hello";
    },

    activeGroups() {
      return this.myTutors;
    },

    timeSlot() {
      return this.$store.state.timeOfDay;
    },

    timetableDay() {
      let date = new Date(this.$store.state.currentTimeTable.data[0].date);
      //console.log(date)
      return format(date, "cccc, do LLL yyyy");
    },

    currentDomain() {
      return this.$store.state.currentDomain;
    },
  },
  methods: {
    test: async function () {
      try {
        alert(JSON.stringify([this.timetable, this.user]));
      } catch (error) {
        alert("Wibble: " + error);
      }
    },

    groups() {
      if (!this.group || this.group.length === 0) {
        this.students = [];
      }

      console.log(this.myTutors);

      console.log("Helllo!");

      let clone = structuredClone(this.group)
        .filter((x) => {
          return (
            this.myTutors.filter((y) => {
              console.log([
                y.visible,
                x.tutor,
                y.name,
                y.visible && x.tutor == y.name,
              ]);
              return y.visible && x.tutor == y.name;
            }).length > 0
          );
        })
        .sort((a, b) => {
          if (a.PupilLastName < b.PupilLastName) {
            return -1;
          }

          if (a.PupilLastName > b.PupilLastName) {
            return 1;
          }

          if (a.PupilFirstName < b.PupilFirstName) {
            return -1;
          }

          if (a.PupilFirstName > b.PupilFirstName) {
            return 1;
          }

          if (a.allData.DOB < b.allData.DOB) {
            return -1;
          }

          if (a.allData.DOB > b.allData.DOB) {
            return 1;
          }

          return 0;
        });

      var final = [];
      let t = 0;
      while (t < clone.length) {
        final.push(clone.slice(t, t + 3));
        t = t + 3;
      }

      this.students = final;
    },

    toggleClassVisibility(classKey) {
      let all = this.myTutors.length;

      if (all === 1) {
        return;
      }

      let currentState = this.myTutors[classKey]?.visible ?? true;

      if (!currentState) {
        // So, we are turning something on
        this.myTutors[classKey].visible = !currentState;
        this.groups();
        return;
      }

      console.log(currentState);

      let active = this.myTutors.filter((x) => x.visible).length;

      if (active > 1) {
        // So, we are turning something on, or there is at least one other active class
        this.myTutors[classKey].visible = !currentState;
        this.groups();
        return;
      }

      if (currentState && all == 2) {
        // this is a toggle!
        if (classKey === 0) {
          this.myTutors[0].visible = false;
          this.myTutors[1].visible = true;
          this.groups();
          return;
        }

        this.myTutors[1].visible = false;
        this.myTutors[0].visible = true;
        this.groups();
        return;
      }
    },

    showAlert: function (index, field, altfield = null) {
      console.log([index, field, altfield, altfield ?? field, this.group]);

      this.$refs.myModal.showModal(
        this.group[index].allData.SEND[altfield ?? field],
        this.group[index].allData.FirstName +
          " " +
          this.group[index].allData.LastName
      );
    },

    showStudentModal: function (index) {
      let student = Object.assign(new Student(), this.group[index]);

      this.$refs.myStudentModal.showModal(student);
    },

    changeRegistrationState: function (index, currentrm, regPeriod) {
      let student = Object.assign(new Student(), this.group[index]);

      this.$refs.myRegisterModal.showModal(
        student,
        currentrm,
        regPeriod,
        this.regMarks
      );
    },
  },

  watch: {
    "$route.params.gid": {
      handler: function (value) {
        //            console.log(value);
        this.loaded = false;

        if (value === "mine") {
          let user = this.$store.getters.currentUserDetails;

          this.group = [];

          this.myTutors = [];

          Object.keys(user.tutorgroups).forEach((x) => {
            this.myTutors.push({
              key: x,
              name: user.tutorgroups[x],
              visible: true,
            });
          });

          let pr = [];
          Object.keys(user.tutorgroups).forEach((value) => {
            pr.push(
              this.$api
                .get("v2/staff/tutors/" + value + "/", true, true)
                .then((x) => {
                  if (!x.members) {
                    x.members = x;
                  }

                  x?.members?.forEach((y) => {
                    y.tutor = user.tutorgroups[value];
                    this.group.push(y);
                  });
                })
            );
          });

          Promise.all(pr).then(() => {
            this.groups();
            this.loaded = true;
          });
        } else {
          this.$api
            .get("v2/staff/tutors/" + value + "/", true, true)
            .then((x) => {
              this.group = x;
              this.groups();
              this.loaded = true;
            });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    //    console.log("Whoot")
  },
};
</script>
